import React, { useEffect, useState } from "react";
import { Col, Form, Row, Button } from "react-bootstrap";

import { ToastMessage } from "../../Components/Toast";
import moment from "moment";
import { BASE_URL } from "../../utils/config";
import toast from "react-hot-toast";
import { AXIOS_INSTANCE } from "../../service";
export default function BannerManagement() {
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [viewBannerList, setViewBannerList] = useState([]);
  const [image, setImage] = useState([]);
  const handleCloseToast = () => setShowToast(false);
  const handleShowToast = () => setShowToast(true);
  const bannerList = async () => {
    try {
      const token = localStorage.getItem("token");
      const { data } = await AXIOS_INSTANCE.get(
        `${BASE_URL}/admin/dashboard-banner`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      let data_ = data.response;
      for (let i = 0; i < data_.length; i++) {
        if (data_[i].bannerUrl === null) {
          continue;
        }
        const url = await getImage(data_[i].bannerUrl);
        data_[i].url = url;
      }
      setViewBannerList(data_);
    } catch (error) {
      console.log(error);
    }
  };
  const getImage = async (key) => {
    try {
      const token = localStorage.getItem("token");
      const { data } = await AXIOS_INSTANCE.post(
        `${BASE_URL}/auth/presigned-url?fileKey=${key}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return data.response;
    } catch (error) {
      return error;
    }
  };
  const handleStatusChange = async (bannerId, status) => {
    try {
      const token = localStorage.getItem("token");
      const { data } = await AXIOS_INSTANCE.put(
        `${BASE_URL}/admin/dashboard-banner`,
        {
          isActive: status,
          bannerId: bannerId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      bannerList();
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
  const deleteBanner = async (bannerId) => {
    /* Create confirm dialog */
    const confirmDialog = window.confirm(
      "Are you sure you want to delete this banner?"
    );
    if (!confirmDialog) {
      return;
    }

    try {
      const token = localStorage.getItem("token");
      const { data } = await AXIOS_INSTANCE.delete(
        `${BASE_URL}/admin/dashboard-banner/${bannerId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success("Banner deleted successfully");
      bannerList();
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  useEffect(() => {
    bannerList();
  }, []);

  return (
    <>
      <div className="page-heading">
        <h3>Dashboard Banner Management</h3>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <a href="/dashboard/add-banner">
              <button
                type="button"
                className="btn btn-primary"
                data-toggle="modal"
                data-target="#addBanner"
              >
                Add banner
              </button>
            </a>
            <table className="table table-image">
              <thead className="text">
                <tr>
                  <th scope="col"> ID</th>
                  <th scope="col">Priority</th>
                  <th scope="col">Image</th>
                  <th scope="col">From Date</th>
                  <th scope="col">To Date</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody className="text">
                {viewBannerList.length > 0 ? (
                  viewBannerList.map((banner, index) => (
                    <tr key={index}>
                      <th>{banner.bannerId}</th>
                      <th>{banner.bannerPriority}</th>
                      <th className="w-25">
                        <img
                          style={{
                            width: "100px",
                            height: "100px",
                          }}
                          src={banner.url}
                          alt="img"
                        />
                      </th>
                      <th>
                        {moment(banner.fromDate).format("DD/MM/yyyy hh:mm")}
                      </th>
                      <th>
                        {moment(banner.toDate).format("DD/MM/yyyy hh:mm")}
                      </th>
                      <th>
                        {/* <button className="btn btn-primary right">Edit</button> */}
                        <button
                          className="btn btn-primary right"
                          onClick={() => deleteBanner(banner.bannerId)}
                        >
                          Delete
                        </button>
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          defaultChecked={banner.isActive}
                          onChange={(e) => {
                            handleStatusChange(
                              banner.bannerId,
                              e.target.checked
                            );
                          }}

                          // label={c.enabled ? "Enabled" : "Disabled"}
                          // checked={c.enabled}
                          // onChange={(e) =>
                          //   handleSwitchChange(e, c, "Series_Enable")
                          // }
                          // onClick={(e) => e.stopPropagation()}
                        />
                      </th>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="3" className="text-center">
                      No Data Available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* <ToastMessage
        show={showToast}
        close={handleCloseToast}
        message={toastMessage}
        type="Success"
        title=""
      /> */}
    </>
  );
}
