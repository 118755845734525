import React, { useCallback, useEffect, useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';

import { sportsActiovationInfo, sportsActiovationInfoChange } from './Service';

const Settings = () => {
  const [sportsActiveList, setSportsActiveList] = useState({});
  useEffect(() => {
    document.getElementById('selectSports')?.classList?.add('d-none');

    return () =>
      document.getElementById('selectSports')?.classList?.remove('d-flex');
  }, []);

  const sportsActiovationInfoHandler = async () => {
    const res = await sportsActiovationInfo();
    if (res && res?.code === 200) {
      setSportsActiveList(res.response);
    }

    console.log(res, 'resSports');
  };

  useEffect(() => {
    sportsActiovationInfoHandler();
  }, []);

  const handleChange = (e) => {
    const { name, checked } = e.target;
    setSportsActiveList((prev) => ({ ...prev, [name]: checked }));
  };

  const sportsActiovationInfoChangeHandler = useCallback(async () => {
    const res = await sportsActiovationInfoChange(sportsActiveList);

    console.log(res, 'resData');
  }, [sportsActiveList]);

  useEffect(() => {
    sportsActiovationInfoChangeHandler();
  }, [sportsActiovationInfoChangeHandler]);

  return (
    <>
      <div className='setting_wrap'>
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <Form.Check
                  checked={sportsActiveList?.cricket}
                  onChange={handleChange}
                  name='cricket'
                  type='switch'
                  id='custom-cricket'
                  label='Cricket'
                />
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card>
              <Card.Body>
                <Form.Check
                  checked={sportsActiveList?.soccer}
                  onChange={handleChange}
                  name='soccer'
                  type='switch'
                  id='custom-soccer'
                  label='Soccer'
                />
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card>
              <Card.Body>
                <Form.Check
                  checked={sportsActiveList?.tennis}
                  onChange={handleChange}
                  name='tennis'
                  type='switch'
                  id='custom-tennis'
                  label='Tennis'
                />
              </Card.Body>
            </Card>
          </Col>
          {/* <Col>
            <Card>
              <Card.Body>
                <Form.Check
                  checked={sportsActiveList?.hrace}
                  onChange={handleChange}
                  name='hrace'
                  type='switch'
                  id='custom-hac'
                  label='Horse Racing'
                />
              </Card.Body>
            </Card>
          </Col> */}
        </Row>
      </div>
    </>
  );
};

export default Settings;
